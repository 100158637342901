<template>
  <div class="calculatorWrapper">
    <div v-if="loading" class="loader">
      <loader />
    </div>
    <h2>{{ t.financingSuggestion }}</h2>
    <div class="calculator">
      <div class="leftContent">
        <slot />
        <div v-show="loanCalculations.length > 0" class="financingType">
          <h3>{{ t.financingTypeTitle }}</h3>
          <div class="financingList">
            <div @click="changeFinancingType(index)" :class="{ item: true, active: index === activeFinancingTypeIndex }" v-for="(item, index) in loanCalculations" :key="item?.config?.provider?.name">{{ t[item?.config?.provider?.name] }}</div>
          </div>
        </div>
        <div class="selectNationality">
          <h3>{{ t['select_nationality'] }}</h3>
          <SearchDropdown :multi="false" name="countrySelect" data-test-id="countrySelect" v-model="selectedCountryId" :options="getLookupFieldValues()" :placeholder="t['select_country']" @update:model-value="onChangeCountry" />
        </div>
        <div class="percentage">
          <span v-if="error" class="error">{{ error }}</span>
          <label for="downpayment">{{ t['preferred_down_payment'] }}</label>
          <!-- <span>{{ `${downPaymentPct} %` }}</span> -->
        </div>
        <span class="input" :class="{ error: downPaymentBelowMin }">
          <input id="downpayment" @input="e => onChangeDownpayment(e)" :value="downPayment" placeholder="Downpayment" />
          <span>{{ getSymbol(currency) }}</span>
          <div class="errors" v-if="calculationErrors.length > 0 && calculationErrors[0].type === 'noCalculationError'">
            <sub class="error" :key="index">
              {{ t['noCalculationError'] }}
            </sub>
          </div>
        </span>
        <div class="preferredDownPayment">
          <InforIcon /> <span>{{ t.preferredDownPaymentDesc ?? 't.preferredDownPaymentDesc' }}</span>
        </div>
        <label for="loanDurationMenu">{{ t['preferred_loan_duration_months'] }} {{ `(${t['months']})` }}</label>
        <div id="loanDurationMenu" class="loanDurationOptions">
          <button v-for="o in paymentTermsOptions" :key="o" :class="{ active: o === paymentTerms }" @click="setPaymentTermsOption(o)">{{ o }}</button>
        </div>
        <div class="preferredLoanDuration">
          <InforIcon /> <span>{{ t.preferredLoanDurationDesc ?? 't.preferredLoanDurationDesc' }}</span>
        </div>
      </div>
      <div class="rightContent">
        <!-- FLEX COMPONENT STARTS  -->
        <div v-if="activeFinancingTypeIndex == 1" class="flexFinancingInfoPanel">
          <h1>{{ t.flexFinancingTitle ?? 't.flexFinancingTitle' }}</h1>
          <div v-show="financingData.length > 0" v-for="(item, index) in financingData" :key="index">
            <div v-if="item.type === 'plan'" class="flexList">
              <h2>{{ t[`flexPlan` + index] ?? `t.flexPlan` + index }}</h2>
              <ul>
                <li v-for="(detail, i) in item.details" :key="i">
                  <span>{{ t[detail.label] }}</span>
                  <span>{{ detail.value.replace('{monthy}', t['monthly']) }}</span>
                </li>
              </ul>
            </div>
            <div v-if="item.type === 'middle'" class="flexList">
              <h2 class="headingLarge">{{ item.title.replace('{after}', t.after).replace('{monthy}', t['monthly']) }}</h2>
              <ul>
                <li v-for="(detail, i) in item.details" :key="i">
                  <span>{{ t[detail.label] }}</span>
                  <span>{{ detail.value }}</span>
                </li>
              </ul>
              <div class="separator" />
            </div>
          </div>
        </div>
        <!-- FLEX COMPONENT ENDS  -->
        <!-- CLASSIC STARTS -->
        <div v-else class="classic">
          <h1>{{ purchaseType }}</h1>
          <ul class="paymentInfo">
            <li>
              <span>
                {{ t['monthly_payment'] }}
              </span>
              <span>{{ monthlyPayment ? `${getSymbol(currency)} ${monthlyPayment.toLocaleString(language)}` : '-' }}</span>
            </li>
            <li>
              <span>
                {{ t['term'] }}
              </span>
              <span>
                {{ termMonths }}
              </span>
            </li>
            <li>
              <span>
                {{ t['down_payment_pct'] }}
              </span>
              <span>{{ downPaymentPct ? `${downPaymentPct.toLocaleString(language)} %` : '-' }}</span>
            </li>
            <li class="apr">
              <span>
                {{ t['apr'] }}
              </span>
              <span>
                {{ aprValue }}
              </span>
            </li>
            <li class="aopBeforeTax">
              <span>
                {{ t['aop_before_tax'] }}
              </span>
              <span> {{ aopBeforeTax }} % </span>
            </li>
            <div v-if="activeFinancingTypeIndex == 2" class="separator" />
            <h1 v-if="activeFinancingTypeIndex == 2 && termMonths" class="headingLarge">{{ `${t.after ?? 't.after'} ${termMonths}` }}</h1>
            <li v-if="activeFinancingTypeIndex == 2">
              <span>{{ t['max_installments'] }}</span>
              <span>{{ balloonField ? `${getSymbol(currency)} ${balloonField}` : '-' }}</span>
            </li>
          </ul>
        </div>
        <!-- CLASSIC ENDS -->
        <div class="paymentTerms">
          <div class="disclaimer">
            <InforIcon />
            {{ t.disclaimer ?? 't.disclaimer' }}
          </div>
          <sub v-html="disclaimer" />
        </div>
        <button class="startOrderButton" :disabled="startOrderDisabled" data-test-id="startOrderCalculator" @click="startOrder">{{ t['start_order'] ?? 'start_order' }}</button>
      </div>
    </div>
    <template v-if="loanDetails">
      <div class="loanDetailsHeader">
        <h2>{{ t['financing_details'] }}</h2>
        <button class="showLoanDetailsButton" @click="showLoanDetails = !showLoanDetails">{{ showLoanDetailsLabel }}</button>
      </div>
      <transition>
        <div v-if="showLoanDetails" class="loanDetails">
          <dl class="loanTable">
            <div v-for="loanDetail in loanDetails" :key="loanDetail.itemLabel" :class="loanDetail.itemLabel">
              <dt>{{ t[loanDetail.itemLabel] }}</dt>
              <dd>
                <b>{{ loanDetail.value }}</b>
              </dd>
            </div>
          </dl>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import { debounce } from '../../logic'
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import { langMixin } from '../lang'
import InforIcon from '../../assets/info-icon.svg'
import SearchDropdown from '../SearchDropdown.ce.vue'

export default {
  name: 'SeezCalculator',
  components: { Loader, InforIcon, SearchDropdown },
  mixins: [langMixin('CALCULATOR_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    listing: { type: String, default: null },
    currency: { type: String, default: '$' },
    startOrderDisabled: { type: Boolean, default: false },
    isShowLoadDetail: {
      type: Boolean,
      default: true
    }
  },
  emits: ['click_start_order_calculator'],
  data() {
    return {
      targetSite: null,
      aopBeforeTax: null,
      paymentTermsOptions: [12, 24, 36, 48, 60, 72, 84, 96],
      apr: null,
      downPayment: null,
      minDownPayment: null,
      maxDownPayment: null,
      paymentTerms: 96,
      loanAmount: null,
      monthlyPayment: null,
      disclaimer: null,
      loading: false,
      downPaymentBelowMin: false,
      downPaymentPct: null,
      showLoanDetails: true,
      data: null,
      loanDetails: null,
      maxPaymentTerms: null,
      paymentTermsAboveMax: null,
      financedAmount: null,
      logo: null,
      error: null,
      loanCalculations: [],
      activeFinancingTypeIndex: 0,
      selectedCountryId: 'QA',
      lastCalculatedCountryId: '',
      calculationErrors: [],
      financingData: [],
      isRtl: document?.dir === 'rtl'
    }
  },
  computed: {
    balloonField() {
      const max_rata = this.financingData.find(i => i.title === 'max_installment')
      if (max_rata) {
        return max_rata.details[0].value
      }
      return '-'
    },
    currencyPerMonth() {
      return this.t.currencyMonth?.replace('{value}', this.getSymbol(this.currency))
    },
    loanValue() {
      return this.loanAmount ? `${this.loanAmount.toLocaleString(this.language)} ${this.getSymbol(this.currency)}` : '-'
    },
    aprValue() {
      if (this.apr) {
        const cappedApr = parseFloat(this.apr).toFixed(2)
        return `${cappedApr} %`
      } else {
        return '-'
      }
    },
    termMonths() {
      return !this.loading ? `${this.paymentTerms} ${this.t.months}` : '-'
    },
    showLoanDetailsLabel() {
      return this.showLoanDetails ? this.t.hide_loan : this.t.show_loan
    },
    purchaseType() {
      return this.downPaymentPct < 20 ? this.t.bank_loan : this.t.purchase_contract
    },
    interestRateValue() {
      return this.data?.interestRate?.toFixed(2) + '%' || ''
    }
  },
  mounted() {
    if (this.listing) this.requestCalculation()
    this.loadTargetSite()
  },
  methods: {
    startOrder() {
      this.$emit('click_start_order_calculator', { calculations: { ...(this.data ?? null) } })
    },
    getLookupFieldValues() {
      let field = {}
      field.lookupGeneratorFunction = 'lookupGeneratorFunction'
      if (field.lookupGeneratorFunction && window.generateNationalities) {
        const vals = window.generateNationalities()
        return vals
      }
      return field.values ?? []
    },
    async loadTargetSite() {
      const query = `{
  currentTargetSite {
    id
    name
    layoutTemplates {
      name
      cmsFields
      cmsRequiredFields
      fields {
        name
        type
        values
        area
        function
        lookupGeneratorFunction
        placeholder
        required
      }
    }
  }
}`

      try {
        const { currentTargetSite } = await this.queryApi(query)
        //TODO: implement logic to get input field from config
        this.targetSite = currentTargetSite
      } catch (e) {
        console.error('Error loadTargetSite', e)
      }
    },
    formatCurrencyToNumber(stringValue) {
      return +stringValue.replace(/[^a-zA-Z0-9 ]/g, '')
    },
    onChangeDownpayment(event) {
      this.calculationErrors = []
      this.error = null
      const value = event.target.value
      const numberDownpayment = this.formatCurrencyToNumber(value)
      this.downPayment = numberDownpayment > 0 ? Number(numberDownpayment)?.toLocaleString(this.language) : ''
      if ((numberDownpayment == null || numberDownpayment < this.minDownPayment) && numberDownpayment === this.minDownPayment) return
      this.calculate()
    },
    calculateLoanDuration() {
      const numberOfMonths = Math.ceil(this.maxPaymentTerms / 12)

      const loanDurationInMonthsArray = Array.from({ length: numberOfMonths }, (item, index) => {
        const isNotDivisibleByNumberOfMonths = index * 12 + 12 > this.maxPaymentTerms
        if (index === 0) return 12
        if (isNotDivisibleByNumberOfMonths) return this.maxPaymentTerms
        return index * 12 + 12
      })

      if (loanDurationInMonthsArray.length > 0) return (this.paymentTermsOptions = loanDurationInMonthsArray)
    },
    calculate: debounce(async function () {
      this.requestCalculation()
    }, 1000),
    checkCalculationTypeErrors(data) {
      let result = []
      let seenErrors = new Set()
      let allLoanAmountsNull = true

      data.forEach(item => {
        // Check if loanAmount is null
        if (item.loanAmount !== null) {
          allLoanAmountsNull = false
        }

        if (item.errors && item.errors.length > 0) {
          item.errors.forEach(error => {
            const errorKey = `${error.field}:${error.errorCode}`
            if (!seenErrors.has(errorKey)) {
              seenErrors.add(errorKey)
              result.push({
                type: error.field,
                message: error.errorCode
              })
            }
          })
        }
      })

      if (allLoanAmountsNull) {
        return [
          {
            type: 'noCalculationError',
            message: 'We were unable to calculate the financing for this listing.'
          }
        ]
      }

      return result
    },
    transformValueToObject(jsonString) {
      try {
        const data = JSON.parse(jsonString)

        if (typeof data === 'number') {
          return data
        }

        // Iterate through the array of objects
        return data.map(item => {
          if (item.value && typeof item.value === 'string') {
            try {
              // Parse the JSON string to an actual object
              item.value = JSON.parse(item.value)
            } catch (e) {
              console.error(`Error parsing JSON string: ${item.value}`, e)
            }
          }
          return item
        })
      } catch (e) {
        console.error('Error parsing JSON string:', e)
        return null
      }
    },
    parseFinancingData(data) {
      const result = []
      data.forEach(category => {
        if (category.santanderBalloon) {
          category.santanderBalloon.forEach(plan => {
            if (plan.key === 'maxirata' && plan.value > 0) {
              result.push({
                type: 'max_installment',
                title: 'max_installment',
                details: [{ label: 'max_rata', value: `${Math.ceil(plan.value).toLocaleString(this.language)} ${this.getSymbol(this.currency)}` }]
              })
            }
          })
        }
        if (category.santanderFlex) {
          category.santanderFlex.forEach(plan => {
            if (plan.key === 'rateVariabili') {
              plan.value.forEach((item, index) => {
                result.push({
                  type: 'plan',
                  title: `Installment Plan ${index + 1}`,
                  details: [
                    { label: 'monthly_payment', value: `${Math.ceil(item.importoRata).toLocaleString(this.language)} ${this.getSymbol(this.currency)}` },
                    { label: 'payment_term', value: index === 1 ? `${item.rataA - item.rataDa} {monthy}` : `${item.rataA + item.rataDa} {monthy}` },
                    { label: 'tan', value: `${item.tan} %` },
                    { label: 'taeg', value: `${item.taeg} %` }
                  ]
                })

                if (item.capitaleResiduo > 0) {
                  result.push({
                    type: 'middle',
                    title: `{after} ${item.rataA + item.rataDa} {monthy}`,
                    details: [{ label: 'max_rata', value: `${item.capitaleResiduo.toLocaleString(this.language)} ${this.getSymbol(this.currency)}` }]
                  })
                }
              })
            }
          })
        }
      })

      return result
    },
    handleFinancingData(loanCalculations) {
      const customAttributes = loanCalculations.map(item => {
        const { calculation } = item

        const customAttributes = calculation?.customAttributes ?? []
        const convertedData = customAttributes.map(i => {
          const value = this.transformValueToObject(i.value)

          return { key: i.key, value }
        })

        return { [item.config.provider.name]: [...convertedData] }
      })

      return this.parseFinancingData(customAttributes)
    },
    async requestCalculation() {
      let downPayment = null
      this.error = null
      try {
        const numberDownpayment = this.formatCurrencyToNumber(this.downPayment)
        downPayment = parseFloat(numberDownpayment)
      } catch (error) {
        //send null
      }

      this.abortController?.abort()
      this.abortController = new AbortController()

      const query = `query loanCalculations($listingId: ID $input: LoanCalculationInput) {
            loanCalculations(listingId: $listingId input: $input) {
              calculation {
                aopBeforeTax
                financedAmount
                loanAmount
                downPayment
                downPaymentPct
                totalPayable
                totalLoanCost
                paymentTerms
                monthlyPayment
                nominalInterestRate
                interestType
                interestRate
                apr
                disclaimer
                nominalInterestRate
                customAttributes {
                  key
                  value
                }
              }
              errors {
                  field
                  errorCode
                }
              aopBeforeTax
              financedAmount
              loanAmount
              downPayment
              downPaymentTotal
              downPaymentTradeIn
              downPaymentPct
              totalPayable
              totalLoanCost
              paymentTerms
              monthlyPayment
              nominalInterestRate
              interestType
              interestRate
              apr
              disclaimer
              downPaymentBelowMin
              nominalInterestRate
              maxPaymentTerms
              paymentTermsAboveMax
              logo
              config {
                terms
                provider {
                  name
                }
                downPayment {
                  min
                  max
                }
              }
            }
          }`

      const payload = {
        input: {
          downPayment: downPayment,
          paymentTerms: this.paymentTerms,
          interestType: 'fixed',
          customerType: this.selectedCountryId === 'QA' ? 'qatari' : 'expat'
        }
      }
      if (this.listing) payload.listingId = this.listing

      this.loading = true
      try {
        const { loanCalculations = [] } = await this.queryApi(query, payload)

        this.calculationErrors = this.checkCalculationTypeErrors(loanCalculations)

        let filteredCalculations = loanCalculations

        //If any error, dot not allow to set those null to the FE.
        if (this.calculationErrors.length > 0) {
          filteredCalculations = loanCalculations.filter(i => i.calculation !== null)
        }

        const flexData = this.handleFinancingData(loanCalculations)

        this.financingData = flexData
        this.loanCalculations = filteredCalculations

        const data = filteredCalculations[this.activeFinancingTypeIndex] || {}
        this.loanAmount = data.loanAmount

        if (this.downPayment == null) this.minDownPayment = data.downPayment
        this.data = data
        this.setCalculatorValues(data)
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error)
          this.calculationErrors = [{ type: 'noCalculationError', message: 'noCalculationError' }]
          this.handleCalculatorError(error.message)
        }
      } finally {
        this.loading = false
      }
    },
    handleCalculatorError(message) {
      if (!this.financedAmount) return
      const messages = {
        'Payout must be less than price': `${this.t.maxDownPaymentText} ${this.maxDownPayment.toLocaleString(this.language)} ${this.getSymbol(this.currency)}`
      }
      this.error = messages[message]
    },
    loadLoanDetails(data) {
      const loanDetailsArray = [data.aopBeforeTax ? { itemLabel: 'aop_before_tax', value: data.aopBeforeTax && data.aopBeforeTax?.toFixed(2) + ' %' } : null, data.loanAmount ? { itemLabel: 'base_amount', value: data.loanAmount ? `${data.loanAmount?.toLocaleString(this.language)} ${this.getSymbol(this.currency)}` : '' } : null, this.paymentTerms ? { itemLabel: 'preferred_payment_terms_monthly', value: this.paymentTerms } : null, data.downPaymentPct ? { itemLabel: 'down_payment_pct', value: data.downPaymentPct && data.downPaymentPct?.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' %' } : null, data.totalLoanCost ? { itemLabel: 'total_loan_costs', value: data.totalLoanCost && `${data.totalLoanCost?.toLocaleString(this.language)} ${this.getSymbol(this.currency)}` } : null, data.financedAmount ? { itemLabel: 'financed_amount', value: data.financedAmount && `${data.financedAmount?.toLocaleString(this.language)} ${this.getSymbol(this.currency)}` } : null, data.interestType ? { itemLabel: 'interest_type', value: this.t[data.interestType] || data.interestType } : null, data.nominalInterestRate ? { itemLabel: 'nominal_interest', value: data.nominalInterestRate ? data.nominalInterestRate.toFixed(2) + '%' : '' } : null, data.apr ? { itemLabel: 'interest_rate', value: data.apr && data.apr?.toFixed(2) + '%' } : null, data.totalPayable ? { itemLabel: 'total_repay', value: data.totalPayable && data.totalPayable?.toLocaleString(this.language) + ` ${this.getSymbol(this.currency)}` } : null].filter(Boolean)

      this.loanDetails = loanDetailsArray
    },
    setPaymentTermsOption(value) {
      this.paymentTerms = value
      this.calculate()
    },
    handleRadioValue() {
      this.fixed = !this.fixed
      this.floating = !this.floating
    },
    setCalculatorValues(financingData) {
      this.aopBeforeTax = financingData.aopBeforeTax
      this.downPayment = financingData.downPayment?.toLocaleString(this.language)
      this.monthlyPayment = financingData.monthlyPayment
      this.disclaimer = financingData.disclaimer
      this.downPaymentPct = financingData.downPaymentPct?.toFixed(0)
      this.apr = financingData.apr

      if (JSON.parse(this.isShowLoadDetail)) {
        this.loadLoanDetails({ ...financingData, ...financingData.calculation })
      }

      this.maxPaymentTerms = financingData.maxPaymentTerms
      this.paymentTermsAboveMax = financingData.paymentTermsAboveMax
      this.interestTypes = financingData.interestTypes
      this.maxDownPayment = financingData.config?.downPayment?.max
      this.logo = financingData.logo
      this.lastCalculatedCountryId = this.selectedCountryId
      this.financedAmount = financingData.financedAmount
      this.paymentTermsOptions = financingData.config.terms
      this.paymentTerms = financingData.paymentTerms
    },
    changeFinancingType(index) {
      const selected = this.loanCalculations[index]
      this.activeFinancingTypeIndex = index
      this.paymentTermsOptions = selected.config.terms

      if (!this.paymentTermsOptions.includes(selected.calculation.paymentTerms)) {
        this.paymentTerms = selected.maxPaymentTerms
        return
      }

      if (selected) {
        this.setCalculatorValues(selected)
      } else {
        if (this.loanCalculations.length > 1) {
          this.downPayment = null
          this.calculate()
        }
      }
    },
    onChangeCountry(countryId) {
      if (this.lastCalculatedCountryId === countryId) return
      this.maxPaymentTerms = this.selectedCountryId === 'QA' ? 48 : 36
      this.calculate()
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.calculatorWrapper {
  @include theme;

  position: relative;
  font-family: var(--base-font);

  input,
  button {
    font-family: var(--base-font);
  }

  .loader {
    position: absolute;
    z-index: 3;
    inset: 0;
  }

  .calculator {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--text-color);
    gap: 2rem;
    padding: 1rem 0rem;

    @media (min-width: 870px) {
      flex-direction: row;
    }

    > .leftContent {
      > h1 {
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0;
        padding: 0 0 2rem 0;
      }

      > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.16px;
      }

      label {
        font-size: 1rem;
        font-weight: 700;
      }

      > .percentage {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;

        > span {
          font-size: 12.8px;
          font-weight: 700;
        }
      }

      > .input {
        margin: 0.5rem 0;
        display: flex;
        position: relative;

        .errors {
          position: absolute;
          top: 6rem;
          left: 0;
        }

        > input {
          font-size: 1rem;
          padding: 0.9rem 1rem;
          border-radius: 24px;
          width: 100%;
          border: 1px solid #e2e2e2;
          margin-block-end: 1rem;
        }

        > span {
          background-color: var(--highlight);
          border-radius: 0px 24px 24px 24px;
          color: var(--background);
          font-size: 14px;
          display: flex;
          align-items: center;
          position: absolute;
          inset-inline-end: 0;
          top: 0.12rem;
          padding: 1.05rem 1.5rem;

          @media (min-width: 768px) {
            top: 0;
            padding: 1.02rem 1.5rem;
          }
        }
      }

      div {
        display: flex;

        svg {
          min-width: 1rem;
        }
        > .downPaymentInfo {
          margin: 0 0 1rem 0;
          padding: 0;
          font-size: 0.8rem;
        }
      }

      .error {
        color: red;
        > input {
          border-left-color: red;
          border-top-color: red;
          border-bottom-color: red;
        }

        > span {
          border-color: red;

          &::before {
            content: '!';
            color: red;
            position: absolute;
            inset-block-start: 1em;
            inset-inline-start: -4em;
            border: 1px solid red;
            border-radius: 2em;
            height: 1em;
            width: 1em;
            padding: 0.125em;
            text-align: center;
            line-height: 1em;
            font-weight: bold;
          }
        }
      }

      @media (min-width: 870px) {
        width: 55%;
      }

      > .loanDurationOptions {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @media (min-width: 1012px) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        gap: 9px;
        margin: 1rem 0 2rem 0;

        > button {
          background-color: var(--background);
          border: none;
          padding: 14px 17px;
          cursor: pointer;
          border-radius: 6px;
          font-size: 14px;
          white-space: nowrap;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--text-color);
          max-height: 2.8rem;
        }

        > button:hover,
        .active {
          font-weight: 600;
          color: var(--highlight);
          max-height: 2.8rem;
        }

        > .active {
          border: 2px solid var(--highlight);
          font-weight: 700;
          max-height: 2.8rem;
        }
      }

      .financingType,
      .selectNationality {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 2.625rem;

        h3 {
          font-weight: 700;
          margin: 0 0 0.875rem 0;
          font-size: 1rem;
        }

        .financingList,
        .customerTypeList {
          display: flex;
          gap: 0.625rem;
          justify-content: start;
          flex-wrap: wrap;

          .item {
            padding: 0.375rem 1.25rem;
            border-radius: 0.5rem;
            border: 1px solid black;
            cursor: pointer;

            &.active {
              background: black;
              color: white;
            }
          }
        }
      }

      .selectNationality,
      .preferredLoanDuration,
      .preferredDownPayment {
        display: none;
      }
    }

    > .rightContent {
      background-color: var(--background);
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
      position: relative;
      font-weight: 700;
      padding: 1em;
      width: 90%;
      position: relative;
      margin: auto;

      .startOrderButton {
        position: absolute;
        bottom: -2.5rem;
        top: revert-layer;
        right: v-bind('isRtl ? "1rem" : "auto"');
        left: v-bind('isRtl ? "auto" : "1rem"');
        display: none;

        @media screen and (min-width: 50rem) {
          bottom: -2rem;
        }

        &:active {
          opacity: 0.7;
        }
      }

      .separator {
        background: rgba(64, 64, 64, 0.2);
        height: 1px;
        width: 100%;
        margin: 23px 0;
      }

      @media (min-width: 415px) {
        max-width: none;
        margin: 0;
      }

      @media (min-width: 870px) {
        width: 30%;
        margin-inline-start: 0.5em;
        padding: 0 20px 20px 20px;
      }

      > h1 {
        font-size: 1.25rem;
        font-weight: 700;
        padding: 0;
        margin: 0;
        padding-block-end: 0.5rem;
      }

      > h5 {
        padding: 0;
        margin: 0;
        font-weight: 400;
      }

      .blue-text {
        color: var(--highlight);
      }

      > p {
        font-size: 2.8rem;

        @media (min-width: 768px) {
          font-size: 3.3rem;
        }

        line-height: 34px;
        margin: 0;
        padding: 2rem 0 2rem 0;

        > span {
          font-size: 1.2rem;

          @media (min-width: 768px) {
            font-size: 1.5rem;
          }

          padding-inline-start: 1rem;
          padding-block-start: 1rem;
        }
      }

      ul.paymentInfo {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 1rem;
        font-weight: 400;

        > li {
          display: flex;
          justify-content: space-between;
          padding: 0.5em 0;

          span:last-child {
            font-weight: 700;
          }
        }
      }

      > table {
        width: 100%;
        font-size: 1rem;

        > tr {
          > th {
            text-align: start;
            color: #545454;
            font-weight: 400;

            @media (min-width: 870px) {
              width: 33%;
              padding-inline-end: 6px;
            }
          }

          > td {
            text-align: start;
            font-weight: 700;
            padding: 0.5em 0;
          }
        }
      }

      > .paymentTerms {
        font-size: 0.9rem;
        color: #757575;
        padding-block-start: 0.5em;
        font-weight: 400;

        > sub {
          line-height: 0.9rem;
        }

        > sub {
          margin-block-start: 2em;
        }

        > .disclaimer {
          display: none;
        }
      }

      > button {
        position: absolute;
        left: 2.5em;
        inset-block-start: -1.5em;
        border-radius: 24px;
        background: var(--highlight);
        border: none;
        color: var(--background);
        padding: 0.75em 1em;
        gap: 1rem;
        width: 12.938em;
        height: 3em;
        cursor: pointer;
        font-weight: 700;
        font-size: 1rem;

        &:hover {
          background-color: #0053cc;
        }
      }

      .interestRate {
        display: none;
      }

      .aopBeforeTax {
        display: none;
      }

      > .flexFinancingInfoPanel {
        h1 {
          font-size: 1.25rem;
          font-weight: 700;
        }

        .flexList {
          h2 {
            font-size: 1rem;
            font-weight: 700;
          }

          .headingLarge {
            font-size: 1.25rem;
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 0.375rem;

            li {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;

              :first-child {
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                color: #3d4345;
              }
            }
          }
        }
      }
    }

    sub {
      display: block;
      font-size: 0.75em;
      line-height: 1.2em;
      color: #afafaf;

      &.error {
        color: red;
      }
    }
  }

  .loanDetailsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h2 {
      font-size: 1em;
      font-weight: 700;
      color: var(--text-color);
    }

    > .showLoanDetailsButton {
      border: none;
      color: var(--highlight);
      background-color: transparent;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 700;
    }
  }

  .loanDetails {
    background-color: #ffffff;
    border-radius: 10px;
    color: var(--text-color);

    > .loanTable {
      display: grid;
      grid-template-columns: 1fr;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      padding: 1rem 0;

      > div {
        display: flex;
        justify-content: space-between;
        padding: 0.8rem 0;
        font-size: 12.8px;
        margin-inline-start: 1rem;
        margin-inline-end: 1rem;
      }

      > div:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }

      .preferred_payment_terms_monthly {
        display: none;
      }
    }
  }
}
</style>
